<template>
    <v-app>
        <transition name="fade-in-up">
            <div>
                <div class="card card-custom card-stretch">
                    <div class="card-body container pt-4">
                        <v-form ref="form" v-model="valid" lazy-validation>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="col-md-12">
                                        <v-text-field
                                            v-model="name"
                                            :rules="[v => !!v || 'Cần phải nhập tên sản phẩm']"
                                            label="Tên sản phẩm"
                                            class="required"
                                            required
                                        ></v-text-field>
                                    </div>

                                    <div class="col-md-12">
                                        <!-- <v-select
                                            v-model="cate_id"
                                            :items="categories"
                                            label="Category"
                                            item-text="Name"
                                            item-value="Id"
                                        ></v-select> -->
                                        <span style="color:#666;">Chọn nhóm sản phẩm</span>
                                        <treeselect
                                                name="demo"
                                                :multiple="false"
                                                :clearable="true"
                                                :searchable="false"
                                                :disabled="false"
                                                :open-on-click="true"
                                                :open-on-focus="true"
                                                :clear-on-select="true"
                                                :close-on-select="true"
                                                :always-open="false"
                                                :append-to-body="false"
                                                :options="parent_categories"
                                                :limit="300"
                                                :max-height="200"
                                                v-model="cate_id"
                                        />
                                    </div>

                                    <div class="col-md-12">
                                        <v-text-field
                                            v-model="cost"
                                            type="number"
                                            label="Giá khuyến mại"
                                            required
                                        ></v-text-field>
                                    </div>

                                    <div class="col-md-12">
                                        <v-text-field
                                            v-model="old_cost"
                                            type="number"
                                            label="Giá chưa khuyến mại"
                                            required
                                        ></v-text-field>
                                    </div>

                                    <div class="col-md-12">
                                        <v-text-field
                                            v-model="type"
                                            label="Loại"
                                        ></v-text-field>
                                    </div>

                                    <!-- <div class="col-md-12">
                                        <v-text-field
                                            v-model="status"
                                            label="Trạng thái"
                                            required
                                        ></v-text-field>
                                    </div> -->

                                    <div class="container col-md-12" style="display:inherit;">
                                        <div class="row">
                                            <div class="col-md-9">
                                                <v-autocomplete
                                                    v-model="tags"
                                                    :items="all_tags"
                                                    label="Từ khoá"
                                                    item-text="Name"
                                                    item-value="Id"
                                                    :multiple="true"
                                                    chips
                                                    deletable-chips
                                                ></v-autocomplete>
                                            </div>
                                            <div class="col-md-3" align="end" style="display: flex;justify-content: center;flex-direction: column;">
                                                <v-dialog v-model="dialog" persistent max-width="600px">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn text v-bind="attrs" v-on="on">
                                                            <v-icon small class="mr-2">mdi-plus</v-icon>Thêm từ khoá
                                                        </v-btn>
                                                    </template>
                                                    <v-card>
                                                        <v-card-title>
                                                            <span class="headline">Thêm từ khoá</span>
                                                        </v-card-title>
                                                        <v-card-text>
                                                            <v-container>
                                                                <v-row>
                                                                    <v-col cols="12" sm="12" md="12">
                                                                        <v-text-field v-model="new_tag_name" label="Tên*" required></v-text-field>
                                                                    </v-col>
                                                                    <v-col cols="12" sm="12" md="12">
                                                                        <v-text-field v-model="new_tag_slug" label="Slug" hint="slug-1"></v-text-field>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-container>
                                                            <small>*Trường bắt buộc</small>
                                                        </v-card-text>
                                                        <v-card-actions>
                                                            <v-spacer></v-spacer>
                                                            <v-btn color="blue darken-1" text @click="dialog = false">Huỷ</v-btn>
                                                            <v-btn color="blue darken-1" text @click="create_tag()">Thêm</v-btn>
                                                        </v-card-actions>
                                                    </v-card>
                                                </v-dialog>
                                                <!--<v-btn @click="show_create_tag()" text><v-icon small class="mr-2">mdi-plus</v-icon>Thêm từ khoá</v-btn>-->
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-12">
                                        <v-switch v-model="status" :label="status ? 'Hiện' : 'Ẩn'"></v-switch>
                                    </div>

                                    <!--<div class="col-md-12">
                                        <v-text-field
                                            v-model="url"
                                            label="Hình ảnh"
                                            required
                                            :append-icon="'mdi-paperclip'"
                                            @click:append="browse_file"
                                        ></v-text-field>
                                    </div>-->

                                    <input type="file" ref="upload" @change="upload_server" style="display:none;" multiple>
                                </div>
                                <div class="col-md-6">
                                    <div class="col-md-12">
                                        <h5>Hình ảnh</h5>
                                        <template v-if="url.length == 0">
                                            <v-btn color="info" @click="browse_file">Tải ảnh lên...</v-btn>
                                        </template>
                                        <template v-if="url.length > 0">
                                            <div class="row">
                                                <div class="col-md-3" v-for="img in url" :key="img">
                                                    <img :src="img" width="100%" />
                                                </div>
                                                <div class="col-md-12">
                                                    <v-btn class="ma-2 float-left" color="error" @click="delete_images">Xoá ảnh</v-btn>
                                                    <v-btn class="ma-2 float-left" color="info" @click="browse_file">Thêm ảnh</v-btn>
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                    <!--<div class="col-md-12">
                                        <v-text-field
                                            v-model="description"
                                            label="Mô tả"
                                            required
                                        ></v-text-field>
                                    </div>-->
                                    
                                    
                                </div>
                                <!-- <div class="col-md-12">
                                    <div style="padding:12px;">
                                        <h5>Mô tả</h5>
                                        <vue-editor v-model="description"></vue-editor>
                                    </div>
                                </div> -->
                                <div class="col-md-12" v-if="activeTab">
                                    <ul class="nav nav-tabs">
                                        <li v-for="tab of tabs" class="nav-item" :key="tab.Id">
                                            <a v-bind:class="{'nav-link d-flex align-items-center tab': true, 'active': (tab.Id == activeTab.Id) }">
                                                <span  v-on:click="activateTab(tab)" class="mr-5">{{tab.Key_name}}</span>
                                                    <i v-on:click="deleteTab(tab)" class="fa fa-times tab-icon" aria-hidden="true"></i>
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link d-flex align-items-center tab add-btn">
                                                <i v-on:click="addNewTab()" class="fa fa-plus tab-icon" aria-hidden="true"></i>
                                            </a>
                                        </li>
                                    </ul>
                                    <div v-if="activeTab.Id" class="card tab-contents">
                                        <div class="card-block">
                                            <div class="card-text">
                                                <div class="col-md-6">
                                                    <div style="padding:12px;">
                                                        <v-text-field
                                                            v-model="activeTab.Key_name"
                                                            label="Key"
                                                            required
                                                        ></v-text-field>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div style="padding:12px;">
                                                        <v-text-field
                                                            v-model="activeTab.Ordinal"
                                                            type="number"
                                                            label="Ordinal"
                                                            required
                                                        ></v-text-field>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div style="padding:12px;">
                                                        <h5>Mô tả</h5>
                                                        <vue-editor v-model="activeTab.Data"></vue-editor>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <v-btn
                                        class="ma-2 float-right"
                                        :loading="loading"
                                        color="info"
                                        @click="update_product"
                                    >
                                        Sửa
                                    </v-btn>
                                    <v-btn
                                        class="ma-2 float-right"
                                        :loading="loading"
                                        @click="cancel"
                                    >
                                        Huỷ
                                    </v-btn>
                                </div>
                            </div>

                        </v-form>
                    </div>
                </div>
            </div>
        </transition>
    </v-app>
</template>


<style lang="scss">
@import "~vuetify/dist/vuetify.css";

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}

// for tabs
$color-transition-duration: 0.8s;
$accent-color: #2980b9;
$x-hover-color: #c0392b;
$smaller-nav-item-padding: 8px;
$icon-size: 0.875rem;

ul.nav-tabs {
    margin-top: 12px;
}

.card.tab-contents {
    border-top: none;
    border-radius: 0;
} 

.nav-link.tab {
    border-radius: 0;
    
    //Override the 16px Bootstrap default to give it a more tab-like feel
    padding-right: $smaller-nav-item-padding;
    
    span {
        transition: color $color-transition-duration;    
        color: black;
        opacity: 0.54;
        &:hover {
            color: $accent-color;
        }
    }
    
    &.active {
        span {
            opacity: 1;
        }
    }
           
    .icon-btn {
        margin-left: 6px;
        text-decoration: none;    
        background-color: transparent;
        border: none;
        cursor: pointer;
        outline: none;
        font-size: $icon-size;

            .fa {
                opacity: 0.54;
                transition: color $color-transition-duration;

                &:hover {
                    color: $x-hover-color;
                }
            }   
    }
    
    &.add-btn {
        padding-left: $smaller-nav-item-padding;        
        
        .icon-btn {
            color: $accent-color;
            margin: 0;    
        }
    }
}

.tab-icon:hover {
    color: #777;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { VueEditor } from "vue2-editor";

export default {
    metaInfo: {
        title: 'Sửa sản phẩm'
    },
    data() {
        return {
            valid: true,
            id: null,
            name: "",
            description: "",
            url: [],
            status: 0,
            cate_id: 1,
            cost: 0,
            old_cost: 0,
            extension: "",
            name_non_accent: "",
            type: 0,
            tags: [],
            loading: false,
            name_rules: [],

            //for tag modal
            dialog: false,
            new_tag_name: "",
            new_tag_slug: "",

            // for tabs
            activeTab: null,
            tabs: [],
        };
    },
    watch: {
        product(val) {
            this.id = val.Id;
            this.name = val.Name;
            this.description = val.Description;
            this.url = val.Url.split(',');
            this.status = val.Status == 1 ? true : false;
            this.cate_id = val.Cate_id;
            this.cost = val.Cost;
            this.old_cost = val.Old_cost;
            this.type = val.Type;
            this.tags = val.Tags || [];
            
            // for tabs
            this.tabs = val.Details || [];
            this.activeTab = this.tabs[0] ? this.tabs[0] : {};

        }
    },
    computed: {
        categories() {
            var server_categories = this.$store.getters['categories/get_categories'];
            return server_categories;
        },
        parent_categories() {
            function find_all_child_cate(cates, cate_id) {
                var child_cates = [];
                for(var j in cates) {
                    if(cates[j].Parent_cate_id == cate_id) {
                        child_cates.push(cates[j]);
                    }
                }
                return child_cates;
            }

            var server_categories = this.$store.getters['categories/get_categories'];
            var data = [];
            for(var i in server_categories) {
                if(!server_categories[i].Parent_cate) {
                    data.push(server_categories[i]);
                    var child_cates = find_all_child_cate(server_categories, server_categories[i].Id);
                    server_categories[i].children = child_cates.map(c => {
                        c.id = c.Id;
                        c.label = c.Name;
                        return c;
                    });
                    server_categories[i].id = server_categories[i].Id;
                    server_categories[i].label = server_categories[i].Name;
                }
            }
            return data;
        },
        product() {
            return this.$store.getters['products/get_product'];
        },
        all_tags() {
            return this.$store.getters['tags/get_tags'];
        }
    },
    components: {
        VueEditor
    },
    methods: {
        async update_product() {
            // this.loading = true;
            if (this.$refs.form.validate()) {
                var details = this.tabs.map(e => {
                    delete e.Id;
                    e.Ordinal = parseInt(e.Ordinal);
                    return e;
                });

                var update_result = await this.update_product_detail(this.id, details);
                if(!update_result) {
                    this.$bvToast.toast("Có lỗi xảy ra", {
                        title: "Lỗi",
                        variant: "danger",
                        solid: true
                    });
                    return;
                }

                var payload = {
                    Id: this.id,
                    Name: this.name,
                    Description: this.description,
                    Url: this.url.join(","),
                    Cate_id: this.cate_id,
                    Status: this.status == true ? 1 : 0,
                    Cost: parseInt(this.cost),
                    Old_cost: parseInt(this.old_cost),
                    Extension: this.extension,
                    Tags: this.tags,
                    Name_non_accent: this.name_non_accent,
                    Type: this.type,
                    toast: this.$root.$bvToast,
                }
                this.$store.dispatch('products/update_product', payload).then(() => {
                    this.loading = false;
                    this.$router.push({ name: 'products-index' })
                }).catch(() => {
                    this.loading = false;
                });
            }
        },
        update_product_detail(product_id, data) {
            return this.$store.dispatch('products/update_product_detail', {product_id, data});
        },
        cancel() {
            this.$router.push({ name: 'products-index' })
        },
        browse_file() {
            this.$refs.upload.click();
        },
        async upload_server() {
            // var self = this;
            var files = this.$refs.upload.files;
            if(files) {
                for(var i = 0; i < files.length; i++) {
                    var url = await this.upload_one(files[i]);
                    this.url.push(url);
                }
            }
        },
        async upload_one(file) {
            var res = await this.$store.dispatch('uploads/upload', {file: file});
            return res.data.url;
        },
        delete_images() {
            this.url = [];
        },

        // for tabs
        activateTab: function(tab) {
            this.activeTab = tab;
        },
        addNewTab: function() {
            if(this.tabs.length == 0) {
                this.tabs.push({Id: 1, Key_name: 'Thông tin chi tiết 1', Data: '', Ordinal: 0});
                this.activeTab = this.tabs[0];
            } else {
                let newId = this.tabs[this.tabs.length - 1].Id + 1;
                this.tabs.push({
                    Id: newId,
                    Key_name: 'Thông tin chi tiết ' + newId,
                    Data: '',
                    Ordinal: 0
                });
                this.activateTab(this.tabs[this.tabs.length - 1]);
            }
        },
        
        deleteTab: function(tab) {
            if(this.tabs.length > 0) {
                this.tabs = this.tabs.filter(t => t.Id != tab.Id);
                if(this.tabs[this.tabs.length - 1]) {
                    this.activateTab(this.tabs[this.tabs.length - 1]);
                } else {
                    this.activateTab({});
                }
            }
        },
        create_tag() {
            this.loading = true;
            var payload = {
                Name: this.new_tag_name,
                Slug: this.new_tag_slug,
                toast: this.$root.$bvToast,
            }
            this.$store.dispatch('tags/create_tag', payload).then(() => {
                this.loading = false;
                this.dialog = false;
                this.$store.dispatch('tags/get_all');
            }).catch(() => {
                this.loading = false;
                this.dialog = false;
            });
        },
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [ { title: "Sản phẩm", route: "/products/index" }, { title: "Sửa sản phẩm" } ]);
        this.$store.dispatch('categories/get_all');
        this.$store.dispatch('tags/get_all');
        this.$store.dispatch('products/get_product_by_id', {id: this.$route.params.id});
    }
};
</script>



